import * as React from "react";
import BurnsideList from "./pages/BurnsideList";
import FremontList from './pages/FremontList';
import WebApp from "./pages/WebApp";
import NoMatchPage from "./pages/NoMatch";
import { listBeers } from "./services/beers";
import BendList from "./pages/BendList";

const App = (props) => {
  // APP WIDE DATA
  const [ beers, setBeers ] = React.useState([]);
  const [ message, setMessage ] = React.useState('Loading...');
  const [ errorMessage, setErrorMessage ] = React.useState('');
  const { locationData, location, scheme } = props;

  // LOAD THE BEER LIST BASED ON LOCATION
  React.useEffect(() => {
    listBeers(locationData)
      .then((resp) => {
        if (resp.error) {
          setErrorMessage('Error loading beer list!')
        } else {
          setBeers(resp.processed)
          setMessage(resp.message || '')
        }
      }, (error) => {
          console.log("Error listing beers!", error)
          setMessage('')
          setErrorMessage('Error loading beer list!')
      })
  }, [locationData])

  if (!location) {
    return (
      <NoMatchPage />
    )
  }


  if (scheme === '4k') {

    if (locationData.url_tag === 'fremont') {
      console.log("Loading 4k Fremont List...")
      return (
        <FremontList 
            location={location} 
            beers={beers}
            message={message}
            locationData={locationData}
        />
      )

    } else if (locationData.url_tag === 'bend') {
      console.log("Loading 4k Bend List...")
      return (
        <BendList
            location={location} 
            beers={beers}
            message={message}
            locationData={locationData}
        />
      )

    } else if (locationData.url_tag === 'burnside') {
      console.log("Loading 4k Burnside List...")
      return (
        <BurnsideList 
            location={location} 
            beers={beers}
            message={message}
            locationData={locationData}
        />
      )

    } else {
      console.log('Location list type not found! '+location)
      return (
        <NoMatchPage />
      )
    }

  } else {
    console.log("Loading Web App...")
    return (
        <WebApp 
            location={location} 
            beers={beers} 
            locationData={locationData} 
            errorMessage={errorMessage}
        />
    )
  }
}

export default App;
