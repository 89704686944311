import * as React from 'react';
import { 
    Box,
    Grid,
    Typography,
} from '@mui/material';

import { WingLogo } from '../WingLogo';
import { WebListedBeer } from './WebListedBeer';

const WebList = (props) => {
    const { locationData } = props;
    let beers = props.list;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} key='toprow'>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '3px dashed #fff',
                    width:'100%',
                    p:2
                }}>
                    <WingLogo 
                        svgProps={{
                            height: {
                                sm:'48px',
                                md: '72px',
                            },
                            display: {
                                xs: 'none',
                                md: 'inline-block'
                            }
                        }} 
                    />
                    <Box sx={{
                        width:'100%',
                        marginLeft: {
                            xs: '18px',
                            md: 'none'
                        },
                        textAlign: {
                            xs: 'left',
                            md: 'center'
                        }
                    }}>
                        <Typography 
                            color="#fff"
                            sx={{
                                display: 'block',
                                fontFamily: 'Anton',
                                fontSize: {
                                    xs: '20px',
                                    md: '42px'
                                }
                            }}
                        >
                            {locationData.web_header_title}
                        </Typography>
                        <Typography 
                            color="secondary.main"
                            sx={{
                                display: 'block',
                                fontFamily: 'Anton',
                                fontSize: '18px',
                                lineHeight: '24px'
                            }}
                        >
                            {locationData.web_header_address}
                        </Typography>
                    </Box>

                    <WingLogo 
                        svgProps={{
                            height: {
                                sm:'48px',
                                md: '72px',
                            },
                            transform: 'scaleX(-1)',
                            display: {
                                xs: 'none',
                                md: 'inline-block'
                            }
                        }} 
                    />

                </Box>
                    
            </Grid>

            {
                beers.map((beer, idx) =>
                    <Grid item 
                        key={beer.name+"-"+idx}
                        xs={12} 
                        sx={{marginBottom:'24px'}}
                    >
                        <WebListedBeer beer={beer} />
                    </Grid>
                )
            }
        </Grid>
           
    )


}

export default WebList;