import React from "react";
import { Box, ThemeProvider, Typography } from "@mui/material";
import WebList from "../components/web/WebList";
import WebAppTheme from "../styles/web";


const WebApp = (props) => {
    const { beers, location, locationData, errorMessage } = props;
    return(
        <ThemeProvider theme={WebAppTheme}>

            <WebList 
                list={beers} 
                location={location} 
                locationData={locationData} 
            />

            { errorMessage !== '' &&
                <Box sx={{p:2}}>
                    <Typography color="error" sx={{fontSize:'1rem', fontWeight:'700', textAlign:'center'}}>
                        {errorMessage}
                    </Typography>
                </Box>
            }

        </ThemeProvider>
        
    )
}

export default WebApp;
