import { 
    createTheme
} from '@mui/material/styles';


const themeOptions = {
    palette: {
        type: 'dark',
        background: {
          default: '#000000'
        },
        // FOTM BLUE
        primary: {
          main: '#2261AE',
        },
        // FOTM YELLOW
        secondary: {
          main: '#FFDC00',
        },
        // FOTM RED
        error: {
          main: '#C8102E',
        },
        // FOTM ORANGE
        warning: {
          main: '#FFA21B',
        },
        // FOTM BROWN
        info: {
          main: '#79431B',
        },
        // FOTM BREWING GREEN
        success: {
          main: '#78BE20',
        },
      },
      typography: {
        fontFamily: 'Bebas Neue',
        fontSize: 16,

        beerName: {
          fontFamily:'"Anton"',
          fontWeight: 500,
          fontSize:'15rem',
          lineHeight: '15rem',
          smallerFontSize: '10rem',
          smallestFontSize: '8.5rem',
          textTransform: 'uppercase',
          largeFontSize: '10rem'
        },

        beerDescription: {
          fontFamily: 'Bebas Neue',
          color: 'rgba(255,255,255,0.9)',
          fontSize: '4rem',
          lineHeight: '3.8rem',
          marginTop: '12px',
          marginBottom: '12px'
        },

        beerDraftTag: {
          fontFamily: '"Jersey 15 Charted"',
          fontSize: '4rem',
          lineHeight: '4rem',
          fontWeight: '500',
          color: '#fff',
          marginRight:'12px'
        },

        beerStyleTag: {
          fontFamily: '"Jersey 15 Charted"',
          fontSize: '8rem',
          lineHeight: '8rem',
          fontWeight: '500',
          color: '#fff',
          marginRight:'12px',
          textWrap:'nowrap'
        },

        boardMessage: {
          fontFamily: 'Anton',
          color: '#fff',
          fontSize: '7rem',
          fontWeight: '500'
        },
      },
};



const WebAppTheme = createTheme(themeOptions);

export default WebAppTheme;

