import { 
    createTheme
} from '@mui/material/styles';


const themeOptions = {
    palette: {
        type: 'dark',
        background: {
          default: '#000000'
        },
        // FOTM BLUE
        primary: {
          main: '#2261AE',
        },
        // FOTM YELLOW
        secondary: {
          main: '#FFDC00',
        },
        // FOTM RED
        error: {
          main: '#C8102E',
        },
        // FOTM ORANGE
        warning: {
          main: '#FFA21B',
        },
        // FOTM BROWN
        info: {
          main: '#79431B',
        },
        // FOTM BREWING GREEN
        success: {
          main: '#78BE20',
        },
      },
      typography: {
        fontFamily: 'Bebas Neue',

      }
};



const AppTheme = createTheme(themeOptions);

export default AppTheme;

