import React from 'react';
import {
    Box, 
    Grid,
    Typography
} from '@mui/material';
import { DraftStyleTag } from './DraftStyleTag';
import { DraftTag } from './DraftTag';
import { DraftLogo } from './DraftLogo';
import { DraftBeerName } from './DraftBeerName';
import { DraftDescription } from './DraftDescription';
import { DraftTagBox } from './DraftTagBox';

const DraftBeer = (props) => {
    const beer = props.beer;
    const logoheight = {
        height: props.logoHeight || '100%',
        maxHeight: props.logoMaxHeight || '100%'
    }
    const { mediumLength, longestLength, sideBox } = props;

    return (
        <Box 
            sx={{
                display: 'flex', 
                alignItems: 'center',
                overflow:'hidden',
                height:props.rowHeight
            }}
        >
            <Box sx={{float:'left', height: '100%'}}>
                <DraftLogo 
                    logoHeight={logoheight.height}
                    beer={beer}
                />
            </Box>
            
            <Box id="beerInfo"
                sx={{
                    display:'inline-block',
                    float: 'left',
                    width:'100%',
                    overflow:'hidden'
                }}
            >
                <Grid container 
                    justifyContent='space-between' 
                    alignItems={'flex-end'}
                >
                    <Grid item xs={12}>
                        <DraftBeerName 
                            beer={beer} 
                            mediumLength={mediumLength} 
                            longestLength={longestLength} 
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            !props.sideBox&&
                            <DraftStyleTag beer={beer} color="#fff" />
                        }
                        
                        {
                            props.sideBox&&
                            <DraftTagBox beer={beer} />
                        }
                    </Grid>
                        {
                            props.withDescription&&
                            <Grid item xs={12} sx={{paddingRight:'10%'}}>
                                <DraftDescription
                                    desc={beer.description}
                                />
                            </Grid>
                        }
                        {
                            !props.sideBox&&
                            <Grid item xs={12}>
                                <DraftTag tagText={beer.abv+"% ABV"} textColor="success.main" />
                                <DraftTag tagText={beer.brewery} textColor="secondary" />
                            </Grid>
                        }
                </Grid>
            </Box>
        </Box>
    )
}

export { DraftBeer }