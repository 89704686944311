import axios from 'axios';


const listBeers = async (loc_data) => {
    let url;
    if (process.env.NODE_ENV === 'development') {
      url = loc_data.local_url;
    } else {
      url = loc_data.api_url;
    }
    return new Promise((resolve, reject) => {
        // DON'T LOAD UNTIL WE HAVE A URL
        if (!url) { console.warn('Url not loaded.'); resolve({processed:[],message:''}); return; }
        console.log("Getting beer list from "+url)
        axios.get(url)
          .then((resp) => {
            if (resp.status === 200 && !resp.data.error) {
                console.log("Beer List loaded!", resp)
                resolve(resp.data);
            } else { throw resp; }
          })
          .catch((e) => {
            console.log("Error listing beers!", e);
            reject({error:e});
          })
    })
}


export { listBeers }