import * as React from 'react';

import { Box, Typography, useTheme } from '@mui/material';


function checkWords(string) {
    const words = string.split(" ");
    let i, word, isLong;
    for (i = 0; i < words.length; i++) {
        word = words[i];
        if (word.length >= 9) {
            console.log("This word is too long: "+word);
            isLong = true;
        }
    }
    return isLong
}

const DraftBeerName = (props) => {
    const { beer, color } = props;
    const mediumLength = props.mediumLength || 13;
    const longestLength = props.longestLength || 19;
    const name = beer.name;
    const theme = useTheme();
    const [nameSize, setSize] = React.useState(theme.typography.beerName.fontSize)

    React.useEffect(() => {
        const nl = name.length || 0;
        const long = checkWords(name)
        if (nl > longestLength) {
            setSize(theme.typography.beerName.smallestFontSize)
        } else if (nl > mediumLength) {
            if (long) {
                setSize(theme.typography.beerName.smallestFontSize)
            } else {
                setSize(theme.typography.beerName.smallerFontSize)
            }
        } else {
            if (long) {
                setSize(theme.typography.beerName.smallerFontSize)
            } else {
                setSize(theme.typography.beerName.fontSize)
            }
        }
    }, [props.beer])

    return (
        <Box>
            <Typography color={color? color : 'secondary'} variant='beerName' sx={{fontSize:nameSize,lineHeight:nameSize}}>
                {name}
            </Typography>
        </Box>
    )

}


export { DraftBeerName }