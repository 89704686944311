import * as React from 'react';
import { Box } from '@mui/material';



const BigLogo = (props) => {
    const src = props.src || null;
    const alt = props.alt || 'FOTM Logo'
    const height = props.height || 500;
    const maxHeight = props.maxHeight || {xs:150,md:250,lg:375}

    return (
        <div>
        {
            src ? (
                <Box
                    component="img"
                    sx={{
                        height: height,
                        maxHeight: maxHeight,
                        p:1
                    }}
                    alt={alt}
                    src={src}
                />

            ) : (
                <></>
            )
            

        }
        </div>
    )
}

export { BigLogo }