import React from 'react';
import {
    Box,
    Grid,
    Typography
} from '@mui/material';
import { WingLogo } from '../WingLogo';


const DraftListFooter = (props) => {
    let message = props.message;
    let footerHeight = props.footerHeight;

    return (
        <Grid item 
            key='botrow'
            xs={12}
            sx={{
                height:footerHeight,
                marginTop: '1rem'
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 120px',
                    justifyContent: 'space-between',
                    borderTop: '10px dashed #fff',
                }}
            >
                <WingLogo height={'85%'} />
                <Typography variant="boardMessage">
                    {message}
                </Typography>
                <WingLogo height={'85%'} sx={{
                    transform: 'scaleX(-1)'
                }} />

            </Box>
            
        </Grid>
    )
}

export {DraftListFooter}