import React from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Typography
} from '@mui/material';

import { locations } from "../../config/locations.config";
import { BigLogo } from "../components/BigLogo";


const NoMatchPage = (props) => {

    return(
        <Container>
            <Grid container 
                justifyContent={'center'} 
                sx={{
                    width:'100%', 
                    textAlign:'center', 
                    marginTop:{xs:'12px',md:'48px'}, 
                    marginBottom: {xs:'12px',md:'48px'} 
                    }}
                >
                <Grid item xs={6} md={4}>
                    <BigLogo 
                        src="https://storage.googleapis.com/public.fotmwings.com/logos/fotm_web.png" 
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <BigLogo 
                        src="https://storage.googleapis.com/public.fotmwings.com/logos/fotm_brewing_web.png" 
                    />
                </Grid>
            </Grid>
            
            <Box 
                sx={{
                    textAlign:'center'
                }}
            >
                <Typography 
                    sx={{
                        fontFamily: "Anton",
                        fontWeight: "400",
                        fontSize: {xs:'1.2rem',md:'2rem'},
                        color: "#fff",
                        textTransform: 'uppercase',
                    }}
                >
                    Select a Fire on the Mountain location to view the taplist!
                </Typography>

            </Box>
            <Divider sx={{m:3}} color="#fff" />
            <Box sx={{textAlign:'center'}}>
                {
                    locations.map((location) => {
                        return (
                            <Box
                                sx={{
                                    display:{xs:'block',md:'inline-block'},
                                    m:2,
                                    p:1,
                                    textAlign:'center'
                                }}
                                key={location.url_tag}
                            >
                                <Typography color="#ccc" fontSize={'0.8rem'}>
                                    {location.web_header_address}
                                </Typography>
                                <Button 
                                    variant="contained" 
                                    size="large" 
                                    sx={{
                                        fontSize:{xs:'1rem',md:'1.8rem'}
                                    }}
                                    href={'/?location='+location.url_tag}
                                >
                                    {location.name.toUpperCase()} Tap List
                                </Button>
                            </Box>
                        )
                    })
                }
            </Box>
        </Container>
    )
}

export default NoMatchPage;
