import React from "react";
import { DraftList } from "../components/draft/DraftList";
import { ThemeProvider } from "@mui/material";
import FremontTheme from '../styles/fremont'


const FremontList = (props) => {
    const beers = props.beers || [];
    const message = props.message;


    return(
        <ThemeProvider theme={FremontTheme}>
            <DraftList 
                list={beers} 
                message={message} 
                withFooter={false} 
                numRows={5} 
                numCols={2}
                screenHeight={2160}
                screenWidth={3840}
                sideBox={true}
                withDescription={false}
            />
        </ThemeProvider>
    )
}

export default FremontList;
