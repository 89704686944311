import React from "react";
import { DraftList } from "../components/draft/DraftList";
import { ThemeProvider } from "@mui/material";
import BendTheme from '../styles/bend'


const BendList = (props) => {
    const beers = props.beers || [];
    const message = props.message;


    return(
        <ThemeProvider theme={BendTheme}>
            <DraftList 
                list={beers} 
                message={message} 
                withFooter={true} 
                withDescription={true}
                numRows={3} 
                numCols={2}
                screenHeight={2160}
                screenWidth={3840}
                mediumLength={12}
                longestLength={18}
            />
        </ThemeProvider>
    )
}

export default BendList;
