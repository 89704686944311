import React from 'react';
import {
    Box
} from '@mui/material';
import WingFlyingLogo from '../assets/wing-transparent-white.svg'

const WingLogo = (props) => {
    let boxProps = props.sx || {};
    let svgProps = props.svgProps || {};
    let height = props.height || '100%';
    return (
        <Box 
            sx={{
                height: '100%',
                display: 'inline-block',
                ...boxProps,
                '& svg': {
                    height: height,
                    ...svgProps
                }
            }}
        >
            <WingFlyingLogo sx={{
                position:'relative'
            }} />
        </Box>
           
    )
}

export { WingLogo }