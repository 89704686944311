import React from 'react';
import {
    Box,
    Typography
} from '@mui/material';

const DraftDescription = (props) => {
    const { beer, desc, color } = props;
    let max_length = props.maxLength || 185;
    const [description, setDescription] = React.useState('Loading...')
    
    React.useEffect(() => {
        if (desc === null || desc === '') {
            // Do nothing
            setDescription('This one is defies any efforts at description')
        } else if (desc.length > max_length) {
            // Trim to max length, then word.
            let length_trim = desc.slice(0, max_length);
            let word_trim = length_trim.slice(0, length_trim.lastIndexOf(" "))
            setDescription( word_trim + '...')
        } else {
            // Send it along un-trimmed
            setDescription(desc)
        }
    })

    return (
        <Box>
            <Typography variant='beerDescription' sx={{display:'inline-block'}} color={color}>
                {description}
            </Typography>

        </Box>
        
           
    )
}

export { DraftDescription }