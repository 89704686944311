import React from 'react';
import {
    Box,
    Grid
} from '@mui/material';
import { DraftBeer } from './DraftBeer';
import { DraftListFooter } from './DraftListFooter';


const DraftList = (props) => {
    // List of beers
    const beers = props.list;
    // Message for bottom of the screen
    const message = props.message;
    // Height of logos
    const logoHeight = props.logoHeight;
    // Whether the beer descriptions should be shown
    const withDescription = props.withDescription || false;
    // Where the logos should sit
    const sideBox = props.sideBox || false;
    // Configure the breakpoints for font size based on
    // text length
    const mediumLength = props.mediumLength || false;
    const longestLength = props.longestLength || false;
    // Screen dimensions
    const screenHeight = props.screenHeight || 2160;
    const screenWidth = props.screenWidth || 3840;
    // Table properties
    let numCols = props.numCols || 2;
    let numRows = props.numRows || beers.length/numCols;

    const withFooter = props.withFooter || false;
    let footerHeight = 0;
    if (withFooter) {
        footerHeight = props.footerHeight || 200;
    }
    const usableHeight = screenHeight - footerHeight;
    let rowHeight = usableHeight/numRows;


    
    return (
        <Box sx={{
            height: screenHeight+'px',
            width: screenWidth+'px',
            margin: 0,
            overflow: 'hidden',
            background: '#000000'
        }}>
            <Grid container sx={{height:'100%', display:'inline-flex'}} alignItems={'flex-start'} >
                {
                    beers.map((beer, idx) =>
                        <Grid item 
                            key={beer.name+"-"+idx}
                            xs={12} 
                            lg={12/numCols} 
                            sx={{
                                height:rowHeight,
                                width:'100%'
                            }}
                        >
                            <Box sx={{padding:'8px'}}>
                                <DraftBeer 
                                    beer={beer} 
                                    rowHeight={rowHeight}
                                    logoHeight={logoHeight}
                                    withDescription={withDescription}
                                    mediumLength={mediumLength}
                                    longestLength={longestLength}
                                    sideBox={sideBox}
                                />

                            </Box>
                            
                        </Grid>
                    )
                }
            {
                withFooter&&
                <DraftListFooter 
                    message={message} 
                    footerHeight={footerHeight} 
                    
                />
            }

                
            </Grid>
        </Box>
        
           
    )
}

export {DraftList}