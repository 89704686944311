import React from 'react';
import {
    Box, 
    Grid,
    Typography
} from '@mui/material';
import { DraftStyleTag } from './DraftStyleTag-bak';
import { DraftTag } from './DraftTag';

const DraftTagBox = (props) => {
    const beer = props.beer;

    return (
        <Box>
            <Grid container>
                <Grid item>
                    <DraftStyleTag tagText={beer.style.toUpperCase()} textColor="#fff" />
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item xs={12}>
                            <DraftTag tagText={beer.brewery} textColor="success.main" />
                        </Grid>

                        <Grid item xs={12}>
                            <DraftTag tagText={beer.abv+"% ABV"} textColor="success.main" />
                        </Grid>
                        
                    </Grid>
                </Grid>
                
                
                
            </Grid>
        </Box>
    )
}

export {DraftTagBox}