import React from 'react';
import {
    Box, 
    Typography
} from '@mui/material';


const DraftStyleTag = (props) => {
    let text = props.tagText;
    const textColor = props.textColor || '#fff';

    return (
        <Box sx={{
            display: 'inline-block',            
        }}>
            <Typography 
                variant='beerStyleTag' 
                color={textColor}
            >
                    {text}
            </Typography>
        </Box>
    )
}

export { DraftStyleTag }