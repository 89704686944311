import React from "react";
import { DraftList } from "../components/draft/DraftList";
import { ThemeProvider } from "@mui/material";
import BurnsideTheme from '../styles/burnside'


const BurnsideList = (props) => {
    const beers = props.beers || [];
    const message = props.message;


    return(
        <ThemeProvider theme={BurnsideTheme}>
            <DraftList 
                list={beers} 
                message={message} 
                withFooter={true} 
                numRows={3} 
                numCols={3}
                screenHeight={2160}
                screenWidth={3840}
                logoHeight={'550px'}
            />
        </ThemeProvider>
    )
}

export default BurnsideList;
