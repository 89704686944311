const locations = [
    {
        name: 'Burnside',
        url_tag: 'burnside',
        api_url: 'https://dupreesdiamondbluecheese.com/locations/burnside/beers',
        local_url: 'http://localhost:4000/locations/burnside/beers',
        page_title: 'FOTM Beers | Burnside Beer List',
        web_header_address: '1708 E Burnside, Portland, OR',
        web_header_title: 'Fire on the Mountain Taplist',
    },
    {
        name: 'Fremont',
        url_tag: 'fremont',
        api_url: 'https://dupreesdiamondbluecheese.com/locations/fremont/beers',
        local_url: 'http://localhost:4000/locations/fremont/beers',
        page_title: 'FOTM Beers | Fremont Beer List',
        web_header_address: '3443 NE 57th Ave, Portland, OR',
        web_header_title: 'Fire on the Mountain Taplist',
    },
    {
        name: 'Bend',
        url_tag: 'bend',
        api_url: 'https://dupreesdiamondbluecheese.com/locations/bend/beers',
        local_url: 'http://localhost:4000/locations/bend/beers',
        page_title: 'FOTM Beers | Bend Beer List',
        web_header_address: '637 NE 3rd St, Bend, OR',
        web_header_title: 'Fire on the Mountain Taplist',
    },
]

function getLocationData(name) {
    let result, i, location;
    try {
        let n = name.toLowerCase();
    
        for (i = 0; i < locations.length; i++) {
            location = locations[i];
            if (location.name.toLowerCase() === n) {
                result = location;
                break;
            } else {
                continue;
            }
        }
    } catch (e) {
        result = {error:"Location Not Found!",msg:e}
    }


    return result;
}

module.exports = {locations, getLocationData};