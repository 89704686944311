import React from 'react';
import {
    Box
} from '@mui/material';

const WebBeerLogo = (props) => {
    let beer = props.beer;
    let backup_logo_url = process.env.BACKUP_LOGO_URL
    let [beer_logo, setLogo] = React.useState(backup_logo_url)

    let logoHeight = props.logoHeight || '100%';

    React.useEffect(() => {
        if (beer.logo === null || beer.logo === '') {
            setLogo(backup_logo_url) 
        } else {
            setLogo(beer.logo)
        }
    })

    return (
        <Box 
            sx={{
                height: logoHeight,
                '& img': {
                    height:logoHeight,
                    maxWidth:logoHeight
                }
            }}
        >
            <img src={beer_logo} />
        </Box>
           
    )
}

export { WebBeerLogo }