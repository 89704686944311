import * as React from 'react';

import { Box, Typography, useTheme } from '@mui/material';


function checkWords(string) {
    const words = string.split(" ");
    let i, word, isLong;
    for (i = 0; i < words.length; i++) {
        word = words[i];
        if (word.length >= 8) {
            console.log("This word is too long! "+word);
            isLong = true;
        }
    }
    return isLong
}

const DraftStyleTag = (props) => {
    const { beer, color } = props;
    const mediumLength = props.mediumLength || 13;
    const longestLength = props.longestLength || 19;
    const text = beer.style;
    const theme = useTheme();
    const [textSize, setTextSize] = React.useState(theme.typography.beerStyleTag.fontSize)

    React.useEffect(() => {
        const nl = text.length || 0;
        const long = checkWords(text)
        if (nl > longestLength) {
            setTextSize(theme.typography.beerStyleTag.smallestFontSize)
            console.log("setting smallest size for "+text)
        } else if (nl > mediumLength) {
            if (long) {
                setTextSize(theme.typography.beerStyleTag.smallestFontSize)
            } else {
                setTextSize(theme.typography.beerStyleTag.smallerFontSize)
            }
            console.log("Setting smaller size for "+text)
        } else {
            if (long) {
                setTextSize(theme.typography.beerStyleTag.smallerFontSize)
            } else {
                setTextSize(theme.typography.beerStyleTag.fontSize)
            }
        }
    }, [props.beer])

    return (
        <Box sx={{
            display: 'inline-block',            
        }}>
            <Typography 
                variant='beerStyleTag' 
                color={color? color : '#fff'}
                sx={{fontSize:textSize,lineHeight:textSize}}
            >
                    {text}
            </Typography>
        </Box>
        
    )

}


export { DraftStyleTag }