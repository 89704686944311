import * as React from 'react';
import { createRoot } from 'react-dom/client';
import AppRouter from './router';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import AppTheme from './styles';
import {
    BrowserRouter,
} from "react-router-dom";


const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <ThemeProvider theme={AppTheme}>

        <CssBaseline />

        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>

    </ThemeProvider>
);
