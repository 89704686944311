import React from 'react';
import {
    Grid,
    Typography
} from '@mui/material';

import { WebBeerLogo } from './WebBeerLogo';
import { WebStyleTag } from './WebStyleTag';

const WebListedBeer = (props) => {
    let beer = props.beer;

    return (
        
        <Grid container 
            alignItems={'center'}
            justifyItems={'center'}
            sx={{
                padding: '0.5rem'
            }}
        >
            <Grid item xs={12} sm={4} md={3}
                sx={{
                    height: {
                        xs: '165px',
                        md: '185px',
                        lg: '200px'
                    },
                    textAlign: {
                        xs: 'center',
                        md: 'right',
                    }
                }}
            >
                <WebBeerLogo 
                    sx={{
                        display:'inline-block',
                    }} 
                    beer={beer} 
                />
            </Grid>
            
            <Grid item xs={12} sm={6} md={8}
                sx={{
                    margin: '12px',
                    marginTop: '0px',
                    textAlign: {
                        xs: 'center',
                        md: 'left',
                    }
                }}
            >
                <Grid container alignItems={'center'}>
                    <Grid item xs={12}>
                        <Typography 
                            color="secondary"
                            variant="beerName"
                        >
                            {beer.name}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sx={{marginBottom:'8px'}}>
                        <WebStyleTag tagText={beer.style.toUpperCase()} textColor="#fff" />
                        <WebStyleTag tagText={beer.abv+"% ABV"} textColor="success.main" />
                        <WebStyleTag tagText={beer.brewery} textColor="secondary" />
                    </Grid>
                    <Grid item>
                        <Typography variant="beerDescription">
                            {beer.description}
                        </Typography>
                    </Grid>
                    
                </Grid>
                
            </Grid>
            
        </Grid>
    )
}

export {WebListedBeer}