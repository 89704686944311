import * as React from "react";
import {
  useLocation,
} from "react-router-dom";
import App from "./app";
import { getLocationData } from "../config/locations.config";
import { Box, CircularProgress } from "@mui/material";
import NoMatchPage from "./pages/NoMatch";


// PARSE URL PARAMS TO GET LOCATION AND SCHEME
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AppRouter = () => {

    const [ locationData, setLocationData ] = React.useState({});
    const [ hasLocation, setHasLoction ] = React.useState(false)

    let params = useQuery();
    let scheme = params.get('scheme');
    let location = params.get('location');

    React.useEffect(() => {
        setLocationData(getLocationData(location))
        setHasLoction(true)
    }, [])

    return (
        <>

            {
                !hasLocation &&
                <Box sx={{width:'100%',textAlign:'center',marginTop:'10vh'}}>
                    <CircularProgress  />
                </Box>
            }
            {
                (hasLocation && locationData.name) &&

                <App 
                    locationData={locationData}
                    scheme={scheme}
                    location={location}
                />
            }
            {
                (hasLocation && !locationData.name) &&

                <NoMatchPage />
            }

        </>
        
    )
}
  

export default AppRouter